import { motion } from "framer-motion";

import NavButton from "./NavButton";
import NavAvatar from "./NavAvatar";
import { ROUTES } from "static/constants";

const navBarVariants = {
  initial: { y: -150 },
  animate: { y: 0 },
  hover: { y: 5 },
  transition: {
    duration: 3.0,
  },
};

const NavBar = (props) => {
  return (
    <motion.nav
      initial={"initial"}
      animate={"animate"}
      variants={navBarVariants}
      className="z-50 flex flex-row px-56 py-4 shadow-sm select-none absolute z-100 w-full bg-white"
    >
      <NavAvatar route={ROUTES[0]} />
      <div className="flex justify-end flex-1 space-x-4">
        {ROUTES.map((route) => {
          return <NavButton key={route.key} route={route} />;
        })}
      </div>
    </motion.nav>
  );
};
export default NavBar;
