import { motion } from "framer-motion";
import coverImg from "assets/images/cover.png";

const sectionVariant = {
  initial: {
    x: "100vw",
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  },
};

const introContainerVariant = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 1.5,
    },
  },
};

const introItemVariant = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
    },
  },
};

const coverImageVariant = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 2.5,
      ease: "easeInOut",
    },
  },
};

const IntroSection = (props) => {
  return (
    <motion.section
      initial={sectionVariant.initial}
      animate={sectionVariant.animate}
      variant={sectionVariant}
      className="flex flex-row h-screen px-56 shadow-sm select-none"
    >
      <motion.div
        initial={"initial"}
        animate={"animate"}
        variants={introContainerVariant}
        className="my-auto space-y-6"
      >
        <motion.p
          variants={introItemVariant}
          className="text-2xl font-body font-light"
        >
          Welcome! 😁
        </motion.p>
        <motion.h1
          variants={introItemVariant}
          className="text-5xl font-light font-display"
        >
          I am <span className="font-semibold">Yash Bhalodi</span>!
        </motion.h1>
        <motion.ul
          variants={introItemVariant}
          className="space-y-2 text-3xl font-light font-body list-none"
        >
          <motion.li variants={introItemVariant}>
            <h2>A Frontend & Mobile Developer</h2>
          </motion.li>
          <motion.li variants={introItemVariant}>
            <h2>A User Experience Enthusiast</h2>
          </motion.li>
        </motion.ul>
      </motion.div>
      <div className="flex-1 h-screen flex justify-items-end align-bottom">
        <motion.img
          src={coverImg}
          alt="Yash Bhalodi"
          className="flex-1 align-bottom object-cover"
          initial={"initial"}
          animate={"animate"}
          variants={coverImageVariant}
        />
      </div>
    </motion.section>
  );
};
export default IntroSection;
