import { BLOG_URL } from "static/constants";
import clsx from "clsx";
import { BlockQuoteSlider } from "./Components";

const BlogPage = (props) => {
  const ctaClass = clsx({
    "px-8 py-4 rounded-md text-white text-lg font-semibold bg-blue-700 transition-all duration-300 ease-in-out transform": true,
    "focus:ring-4 focus:ring-blue-500 ring-offset-blue-500": true,
    "hover:bg-blue-100 hover:text-blue-900 hover:shadow-md": true,
  });

  return (
    <>
      <section className="flex h-screen px-56 shadow-sm">
        <div className="flex flex-col justify-center align-middle w-full">
          <p className="text-xl font-semibold my-4">
            I write on various topics that I find interesting and useful.
            <ul className="text-lg ml-4 my-4 list-disc">
              <li>
                Web Development -{" "}
                <span className="font-medium">React, JavaScript, etc.</span>
              </li>
              <li>
                UX Design -{" "}
                <span className="font-medium">Design to make life better</span>
              </li>
              <li>
                Programming & Software Development -{" "}
                <span className="font-medium">
                  Concepts and Techniques used
                </span>
              </li>
            </ul>
            I also write about my thoughts on non-fiction books that I read.
            <br />
            <br />
            <span className="font-medium">
              I write to share and enhance my learnings.
            </span>
          </p>
          <div className="mt-8">
            <a
              href={BLOG_URL}
              target="_blank"
              rel="noreferrer"
              className={ctaClass}
            >
              Explore My Blog
            </a>
          </div>
        </div>
      </section>
      <div className="absolute bottom-0 w-full px-56 py-4">
        <div className="px-12">
          <BlockQuoteSlider />
        </div>
      </div>
    </>
  );
};

export default BlogPage;
