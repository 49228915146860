import { Route, Switch } from "react-router-dom";

import { HomePage, NotFoundPage, BlogPage } from "Pages";

import { NavBar } from "CommonComponents";

const App = () => {
  return (
    <>
      <NavBar />
      <Switch>
        <Route path="/" exact>
          <HomePage />
        </Route>
        <Route path="/blog" exact>
          <BlogPage />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </>
  );
};

export default App;
