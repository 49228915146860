import React, { useEffect, useState } from "react";
import { WRITING_QUOTES } from "static/constants";

const BlockQuoteSlider = (props) => {
  const [index, setIndex] = useState(0);
  const quote = WRITING_QUOTES[index];

  useEffect(() => {
    setInterval(() => {
      setIndex((index) => {
        if (index === WRITING_QUOTES.length - 1) {
          return 0;
        }
        console.log({ index });
        return index + 1;
      });
    }, 5000);
  }, []);

  return (
    <blockquote className="border-l-4 rounded-md bg-gray-100 text-blue-900 font-medium pl-4 pr-8 py-2 h-16 hover:scale-110 transition-all ease-in-out duration-300 transform">
      {quote.text} -&nbsp;<span className="text-gray-900">{quote.author}</span>
    </blockquote>
  );
};

export default BlockQuoteSlider;
