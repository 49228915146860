const Brand = (props) => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="45" height="45" rx="8" fill="#1E3A8A" />
      <path
        d="M31.6611 18.1685C33.0001 16.0324 33.8773 13.8964 33.8773 11.5157C32.6835 7.44429 29.388 8.35397 27.7469 9.4364C26.2529 10.4218 23.3609 13.9838 20.9789 18.0542C18.962 15.0304 17.1599 11.5321 16.181 10.1848C14.1117 7.33674 7.32878 8.16736 8.5934 11.2529C9.85801 14.3384 16.9858 25.1374 16.9858 25.1374C14.8015 28.6975 14.8014 28.9348 11.1226 34.7497C14.4142 37.0856 17.7052 36.4342 19.9339 34.7497M23.1938 14.5756C28.3672 6.14999 32.9658 8.28606 33.7705 11.2529M19.9339 34.7497C22.693 31.8157 24.9846 28.3004 26.6018 26.3568C29.169 23.2714 32.4154 21.1826 35.3391 22.7967C38.9311 24.7797 33.604 31.2589 32.2383 32.8799L32.235 32.8837C30.6255 34.8621 27.1766 36.5625 24.6474 36.5625C22.4427 36.5625 21.2193 35.9754 19.9339 34.7497Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default Brand;
