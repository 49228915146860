import { ApolloClient, InMemoryCache } from "@apollo/client";
import { CMS_ENDPOINT } from "static/constants";

const client = new ApolloClient({
  uri: CMS_ENDPOINT,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
});

export default client;
