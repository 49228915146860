export const CMS_ENDPOINT = "https://cms-yash-bhalodi.herokuapp.com/graphql";
export const CMS_URL = "https://cms-yash-bhalodi.herokuapp.com";

export const BLOG_URL = "https://blog.yashbhalodi.me";

export const ROUTES = [
  {
    key: "home",
    pathname: "/",
    label: "Home",
  },
  {
    key: "blog",
    pathname: "/blog",
    label: "Blog",
  },
];

export const WRITING_QUOTES = [
  { text: `Words are a lens to focus one’s mind.`, author: "Ayn Rand" },
  {
    text: `Some writers confuse authenticity, which they ought always to aim at, with originality, which they should never bother about.`,
    author: "W.H. Auden",
  },
  {
    text: `The secret of good writing is telling the truth.`,
    author: "Gordon Lish",
  },
  {
    text: `And by the way, everything in life is writable about if you have the outgoing guts to do it, and the imagination to improvise. The worst enemy to creativity is self-doubt.`,
    author: "Sylvia Plath",
  },
  {
    text: `If you don’t have time to read, you don’t have the time (or the tools) to write. Simple as that.`,
    author: "Stephen King",
  },
  {
    text: `Focus more on your desire than on your doubt, and the dream will take care of itself.`,
    author: `Mark Twain`,
  },
];
