import { Link as RouteLink } from "react-router-dom";
import { Brand } from "CommonComponents";
import clsx from "clsx";

const NavAvatar = (props) => {
  const { route } = props;
  const navAvatarClass = clsx({
    "inline-block rounded-md outline-none font-title my-auto transition duration-300 ease-in-out transform": true,
    "focus:ring-2 focus:ring-blue-300 ring-offset-blue-300": true,
  });

  return (
    <RouteLink to={route.pathname} key={route.key} className={navAvatarClass}>
      <Brand />
    </RouteLink>
  );
};

export default NavAvatar;
