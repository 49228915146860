import { Link as RouteLink, useLocation } from "react-router-dom";
import clsx from "clsx";

const NavButton = (props) => {
  const { route } = props;
  const location = useLocation();
  const active = location.pathname === route.pathname;

  const navButtonClass = clsx({
    "inline-block px-4 py-2 rounded-md outline-none font-title my-auto transition duration-300 ease-in-out transform": true,
    "focus:ring-2 focus:ring-blue-300 ring-offset-blue-300": true,
    "hover:bg-blue-100 hover:text-blue-700": true,
    "bg-blue-50": active,
  });

  return (
    <RouteLink to={route.pathname} key={route.key} className={navButtonClass}>
      {route.label}
    </RouteLink>
  );
};

export default NavButton;
